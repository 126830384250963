.containerModelviewer {
  height: 100svh !important;
  position: relative;
  text-align: center;
  z-index: 10;
  width: 100%;
  transition: height 0.5s ease, margin-top 0.5s ease;
}

.expandedContainer {
  /* Rimuovi il ridimensionamento del container principale */
  /* height: 60svh; */
  /* margin-top: -10svh; */
}

.expandedModelViewer {
  height: 80svh; /* Adatta l'altezza come necessario per ottenere l'effetto desiderato */
  transition: height 0.5s ease;
}


.clippedButton {
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed; /* Usa fixed per ancorare il pulsante in un punto della finestra */
  left: 50%;
  transform: translate(-50%);
  border-radius: 25px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  color: black;
  padding: 10px;
  z-index: 25;
  margin-top: 15px;
  bottom: 10svh; /* Aumenta la distanza dal bordo in modo da evitare sovrapposizioni */
  transition: transform 0.8s ease;
}


.buttonMovedDown {
  /* transform: translate(-50%, -100px); */
  position: fixed;
  bottom: 9svh;
  
}

.clippedIconButton {
  font-size: 28px;
  margin-right: 8px;
  height: 30px;
  width: 30px;
  /* filter: brightness(0) invert(1); */
}

.pulsingCircle {
  z-index: 999;
  display: flex;
  background-position: center center;
  align-items: center; 
  justify-content: center;
  padding: 0;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: radial-gradient(circle, #cd171a, #cd171a, #bc2f32);
  background-size: 150% 150%;
  animation: pulseWave 2s infinite;
  border-color: transparent;
  --min-hotspot-opacity: 1;
  background-color: transparent;
  color: white;
  font-weight: bold;
}

.pulsingCircleText {
  color: white;
  width: 15px;
  height: 15px;
  left: -7px;
  top: -7px;
  background-color: black;
  border-radius: 50%;
  border: none;
  display: block;
}

.hidden {
  display: none !important;
}


.hotspotContent {
  position: fixed;
  bottom: 20svh;
  left: 50%; /* Posizionamento centrale orizzontale */
  width: 80%; /* O 40% a seconda del dispositivo */
  border-radius: 12px;
  cursor: pointer;
  background: #cd171a60;
  backdrop-filter: blur(7.1px);
  -webkit-backdrop-filter: blur(7.1px);
  border: 1px solid rgba(255, 255, 255, 0);
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 17px;
  font-weight: 700;
  text-align: justify;
  color: white;
  line-height: 1.4;
  z-index: 1000;
}



/* Regole specifiche per schermi desktop */
@media only screen and (min-width: 1024px) {
  .hotspotContent {
    width: 40%; /* Riduce la larghezza al 40% per schermi più grandi */
  }
}



.hotspotHidden {
  display: none;
}

@keyframes pulseWave {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.2);
    opacity: 0.50;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

/* for iPad */
@media only screen and (max-device-width: 1366px) {
  .clippedButton {
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute; /* Cambiato da absolute a fixed */
  left: 50%;
  transform: translate(-50%); /* Centrato orizzontalmente e verticalmente */
  border-radius: 25px;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: bold;
  color: black;
  padding: 10px;
  z-index: 25;
  transition: transform 0.8s ease;
}


  .clippedIconButton {
    color: black;
    font-size: 50px;
    margin-right: 8px;
    width: 35px;
    height: 35px;
    /* filter: brightness(0) invert(1); */
  }
}

/* per dispositivi mobili */
@media only screen and (max-width: 767px) {
  .clippedIconButton {
    color: black;
    font-size: 40px;
    margin-right: 6px;
    width: 16px;
    height: 16px;
    /* filter: brightness(0) invert(1); */
  }
}
